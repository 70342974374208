exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apartamentos-altamira-1-tsx": () => import("./../../../src/pages/apartamentos/altamira-1.tsx" /* webpackChunkName: "component---src-pages-apartamentos-altamira-1-tsx" */),
  "component---src-pages-apartamentos-altamira-2-tsx": () => import("./../../../src/pages/apartamentos/altamira-2.tsx" /* webpackChunkName: "component---src-pages-apartamentos-altamira-2-tsx" */),
  "component---src-pages-apartamentos-altamira-bajo-tsx": () => import("./../../../src/pages/apartamentos/altamira-bajo.tsx" /* webpackChunkName: "component---src-pages-apartamentos-altamira-bajo-tsx" */),
  "component---src-pages-apartamentos-garceran-1-derecha-tsx": () => import("./../../../src/pages/apartamentos/garceran-1-derecha.tsx" /* webpackChunkName: "component---src-pages-apartamentos-garceran-1-derecha-tsx" */),
  "component---src-pages-apartamentos-garceran-1-izquierda-tsx": () => import("./../../../src/pages/apartamentos/garceran-1-izquierda.tsx" /* webpackChunkName: "component---src-pages-apartamentos-garceran-1-izquierda-tsx" */),
  "component---src-pages-apartamentos-garceran-2-derecha-tsx": () => import("./../../../src/pages/apartamentos/garceran-2-derecha.tsx" /* webpackChunkName: "component---src-pages-apartamentos-garceran-2-derecha-tsx" */),
  "component---src-pages-apartamentos-garceran-2-izquierda-tsx": () => import("./../../../src/pages/apartamentos/garceran-2-izquierda.tsx" /* webpackChunkName: "component---src-pages-apartamentos-garceran-2-izquierda-tsx" */),
  "component---src-pages-apartamentos-garceran-atico-tsx": () => import("./../../../src/pages/apartamentos/garceran-atico.tsx" /* webpackChunkName: "component---src-pages-apartamentos-garceran-atico-tsx" */),
  "component---src-pages-apartamentos-garceran-bajo-derecha-tsx": () => import("./../../../src/pages/apartamentos/garceran-bajo-derecha.tsx" /* webpackChunkName: "component---src-pages-apartamentos-garceran-bajo-derecha-tsx" */),
  "component---src-pages-apartamentos-garceran-bajo-izquierda-tsx": () => import("./../../../src/pages/apartamentos/garceran-bajo-izquierda.tsx" /* webpackChunkName: "component---src-pages-apartamentos-garceran-bajo-izquierda-tsx" */),
  "component---src-pages-apartamentos-index-tsx": () => import("./../../../src/pages/apartamentos/index.tsx" /* webpackChunkName: "component---src-pages-apartamentos-index-tsx" */),
  "component---src-pages-apartamentos-piso-mendoza-tsx": () => import("./../../../src/pages/apartamentos/piso-mendoza.tsx" /* webpackChunkName: "component---src-pages-apartamentos-piso-mendoza-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-informacion-turistica-tsx": () => import("./../../../src/pages/informacion-turistica.tsx" /* webpackChunkName: "component---src-pages-informacion-turistica-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-privacidad-tsx": () => import("./../../../src/pages/privacidad.tsx" /* webpackChunkName: "component---src-pages-privacidad-tsx" */)
}

